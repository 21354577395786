export const Colors = {
  Black: {
    900: '#23242A',
    200: '#DDE2EB',
  },
  Gray: {
    600: '#757575',
    300: '#E0E0E0',
  },
  White: '#ffffff',
  Background: '#EBEBEB',
  Yellow: {
    500: '#F2C94C',
    200: '#fff9e6',
    100: '#FFF4D4',
  },
  Red: { 400: '#F87171' },
};

export const Fonts = {
  Helvetica: '"HelveticaNeue", sans-serif',
};

export const Shadows = {
  main: 'rgb(212 212 211 / 59%) 0px 2px 4px 0px',
  notification: '0px 4px 14px rgba(136, 169, 200, 0.3)',
};

export const Sizes = {
  headerHeight: '64px',
};

export const Gradients = {
  bodyBackground: `linear-gradient(180deg, ${Colors.Yellow[100]}, ${Colors.White})`,
};

export const Transitions = {
  duration: '0.25s',
  all: 'all 0.25s ease',
};

export const BorderRad = {
  s: '8px',
  m: '24px',
  round: '50%',
  full: '1000px',
};
